import React, { useRef } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const form = useRef();
  const { t } = useTranslation();
  const language = localStorage.getItem("language") || "en";
  const sendEmail = (e) => {
    e.preventDefault();
    //emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
    emailjs
      .sendForm(
        "gmail",
        "YOUR_TEMPLATE_ID",
        e.target,
        "d9b2e0f5fc72cb94792110e8ff2028f3-us16"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <>
      <footer className="site-footer style1">
        {/* <div className="section-full p-t50 p-b20 bg-primary text-white overlay-primary-dark footer-info-bar">
					<div className="container">
						<div className="row">
							<div className="col-lg-3 col-md-6 col-sm-6 m-b30">
								<div className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1">
									<div className="icon-content">
										<h5 className="dlab-tilte">
											<span className="icon-sm"  style={language === 'ar' ? { marginRight: '70%' } : {}}><i className="ti-location-pin"></i></span> 
											{t('CompanyAddress')}
										</h5>
										<p className="op7">{t('Address')}</p>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6 m-b30">
								<div className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1">
									<div className="icon-content">
										<h5 className="dlab-tilte">
											<span className="icon-sm" style={language === 'ar' ? { marginRight: '70%' } : {}}><i className="ti-email"></i></span> 
											{t('Email')}
										</h5>
										<p className="m-b0 op7">info@aromaegypt.com</p>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6 m-b30">
								<div className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1">
									<div className="icon-content">
										<h5 className="dlab-tilte">
											<span className="icon-sm" style={language === 'ar' ? { marginRight: '70%' } : {}}><i className="ti-mobile"></i></span> 
											{t('PhoneNumbers')}
										</h5>
										<p className="m-b0 op7">00201070562111</p>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6 m-b30">
								<div className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1">
									<div className="icon-content">
										<h5 className="dlab-tilte">
											<span className="icon-sm" style={language === 'ar' ? { marginRight: '70%' } : {}}><i className="ti-alarm-clock"></i></span> 
											{t('OfficeHours')}
										</h5>
										<p className="m-b0 op7">{t('Hours')}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>				 */}
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="widget widget_about">
                  <h4 className="footer-title">{t("footerAboutTitle")}</h4>
                  <p style={{ textAlign: "justify" }}>{t("footerAbout")}</p>
                  <Link to={"/about"} className="readmore">
                    {t("ReadMore")}
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="widget">
                  <h4 className="footer-title">{t("QuickLinks")}</h4>
                  <ul className="list-2">
                    <li>
                      <Link to={"/about"}>{t("AboutUs")}</Link>
                    </li>
                    <li>
                      <Link to={"/products"}>{t("Products")}</Link>
                    </li>
                    <li>
                      <Link to={"/contact"}>{t("ContactUs")}</Link>
                    </li>
                    <li>
                      <Link to={"/faqs"}>{t("FAQs")}</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="widget widget_subscribe">
                  <h4 className="footer-title">{t("Newsletter")}</h4>
                  <form className="dzSubscribe" ref={form} onSubmit={sendEmail}>
                    <div className="dzSubscribeMsg"></div>
                    <div className="form-group">
                      <div className="input-group">
                        <input
                          name="dzEmail"
                          required="required"
                          type="email"
                          className="form-control"
                          placeholder={t("YourEmailId")}
                        />
                        <div className="input-group-addon">
                          <button
                            name="submit"
                            value="Submit"
                            type="submit"
                            className="site-button fa fa-paper-plane-o"
                          ></button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom footer-line">
          <div className="container">
            <div className="footer-bottom-in">
              <div className="footer-bottom-social">
                <ul className="dlab-social-icon dez-border">
                  <li>
                    <a
                      className="fa fa-facebook"
                      href="https://www.facebook.com/aromaflooring"
                      target="_blank"
                      rel="noopener noreferrer"
                    ></a>
                  </li>
                  <li>
                    <a
                      className="fa fa-instagram"
                      href="https://www.instagram.com/aromaflooring"
                      target="_blank"
                      rel="noopener noreferrer"
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
