import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';
import { withTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';

class Header extends Component {

    componentDidMount() {

        // var searchBtn = document.getElementById("quik-search-btn")
        // var searchPopup = document.querySelector(".dlab-quik-search")
        // var closeBtn = document.getElementById("quik-search-remove")

        // searchBtn.addEventListener('click',function(){
        //     searchPopup.style.display = "block"
        //     searchPopup.classList.add("On")
        // })

        // closeBtn.addEventListener('click',function(){
        //     searchPopup.style.display = "none"
        //     searchPopup.classList.remove("On")
        // })

        // sidebar open/close

        var btn = document.querySelector('.navicon');
        var nav = document.querySelector('.header-nav');
       
        function toggleFunc() {
            btn.classList.toggle("open");
            nav.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);


        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.header-nav > ul > li'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }

        function checkLi(current) {
            const active = current.classList.contains("open")
            navUl.forEach(el => el.classList.remove('open'));
            //current.classList.add('open');
           
            if(active){
                current.classList.remove('open') 
                console.log("active")
            } else{
                current.classList.add('open');
                console.log("close")
            }
        }

    }
    render() {
        const { activePage } = this.props;
        const { t } = this.props;
        return (
            <>
                <header className="site-header mo-left header">

                    <Sticky innerZ={999} enabled={true}>
                        <div className="sticky-header main-bar-wraper navbar-expand-lg">
                            <div className="main-bar clearfix ">
                                <div className="container clearfix">

                                    <div className="logo-header mostion">
                                        <Link to={'/'}>  <LazyLoad ><img src={require('../../images/aromalogo1.png')} alt="" /> </LazyLoad></Link>
                                    </div>

                                    <button className="navbar-toggler navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </button>

                                    {/* <div className="extra-nav">
                                        <div className="extra-cell">
                                            <button id="quik-search-btn" type="button" className="site-button-link"><i className="la la-search"></i></button>
                                        </div>
                                    </div>

                                    <div className="dlab-quik-search ">
                                        <form action="#">
                                            <input name="search" value="" type="text" className="form-control" placeholder="Type to search" />
                                            <span id="quik-search-remove"><i className="ti-close"></i></span>
                                        </form>
                                    </div> */}

                                    <div class={`header-nav navbar-collapse collapse justify-content-end`} id="navbarNavDropdown">
                                        <div className="logo-header d-md-block d-lg-none">
                                            <Link to={'/'}>  <LazyLoad><img src={require('../../images/aromalogo1.png')} alt="" /> </LazyLoad></Link>
                                        </div>
                                        <ul className="nav navbar-nav">
                                            <li className={activePage === 'home' ? 'active has-mega-menu homedemo' : 'has-mega-menu homedemo'}> <Link to={'/'}>{t('Home')}</Link>
                                            </li>
                                        <li className={activePage === 'products' ? 'active has-mega-menu homedemo' : 'has-mega-menu homedemo'}> <Link to="/products/1">{t('Products')}</Link></li>
                                        <li className={activePage === 'about' ? 'active has-mega-menu homedemo' : 'has-mega-menu homedemo'}> <Link to="/about">{t('AboutUs')}</Link></li>
                                        <li className={activePage === 'faqs' ? 'active has-mega-menu homedemo' : 'has-mega-menu homedemo'}> <Link to="/faqs">{t('FAQs')}</Link></li>
                                        <li className={activePage === 'gallery' ? 'active has-mega-menu homedemo' : 'has-mega-menu homedemo'}> <Link to="/gallery">{t('Gallery')}</Link></li>
                                        <li className={activePage === 'contact' ? 'active has-mega-menu homedemo' : 'has-mega-menu homedemo'}>
                                            <Link to="/contact">{t('ContactUs')}</Link>
                                        </li>
                                        </ul>
                                        <div className="dlab-social-icon">
                                            <ul>
                                                <li><Link className="site-button circle fa fa-facebook" to={'/#'}></Link></li>
                                                <li><Link className="site-button  circle fa fa-twitter" to={'/#'}></Link></li>
                                                <li><Link className="site-button circle fa fa-linkedin" to={'/#'}></Link></li>
                                                <li><Link className="site-button circle fa fa-instagram" to={'/#'}></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Sticky>

                </header>

            </>
        )
    }
}
export default withTranslation()(Header);


