import React, { useRef } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

const Footer3 = () => {
  const form = useRef();
  const { t } = useTranslation();
  const sendEmail = (e) => {
    e.preventDefault();

    //emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
    emailjs
      .sendForm(
        "YOUR_SERVICE_ID",
        "YOUR_TEMPLATE_ID",
        e.target,
        "d9b2e0f5fc72cb94792110e8ff2028f3-us16"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <>
      <footer className="site-footer footer-gray-1">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-3 col-md-6 col-sm-12 wow fadeIn"
                data-wow-delay="0.2s"
              >
                <div className="widget border-0">
                  <h6 className="m-b20 text-white font-weight-300 text-uppercase">
                    {t("QuickLinks")}
                  </h6>
                  <ul className="list-2">
                    <li>
                      <Link to="/about">{t("AboutUs")}</Link>
                    </li>
                    <li>
                      <Link to="/products">{t("Products")}</Link>
                    </li>
                    <li>
                      <Link to="/contact">{t("ContactUs")}</Link>
                    </li>
                    <li>
                      <Link to="/faqs">{t("FAQs")}</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 col-sm-12 wow fadeIn"
                data-wow-delay="0.4s"
              >
                <div className="widget">
                  <h6 className="text-white font-weight-300 text-uppercase">
                    {t("Newsletter")}
                  </h6>
                  <div className="subscribe-form m-b20 m-t15">
                    <form
                      className="dzSubscribe"
                      ref={form}
                      onSubmit={sendEmail}
                    >
                      <div className="dzSubscribeMsg"></div>
                      <div className="input-group">
                        <input
                          name="dzEmail"
                          required="required"
                          className="form-control radius-no"
                          placeholder={t("YourEmailId")}
                          type="email"
                        />
                        <span className="input-group-btn">
                          <button
                            name="submit"
                            value="Submit"
                            type="submit"
                            className="site-button radius-no btnhover14"
                          >
                            {t("FormButton")}
                          </button>
                        </span>
                      </div>
                    </form>
                  </div>
                  <h6 className="m-b10 text-white font-weight-300 text-uppercase">
                    {t("ConnectWithUs")}
                  </h6>
                  <ul className="list-inline m-a2">
                    <li>
						<div  className="site-button facebook sharp">
                      <a
                        className="fa fa-facebook"
                        href="https://www.facebook.com/aromaflooring"
                        target="_blank"
                        rel="noopener noreferrer"
                      ></a>
					  </div>
                    </li>
                    <li>
                      <div className="site-button instagram sharp">
                      <a
                        className="fa fa-instagram"
                        href="https://www.instagram.com/aromaflooring"
                        target="_blank"
                        rel="noopener noreferrer"
                      ></a>
					  </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="col-lg-5 col-md-12 col-sm-12 wow fadeIn"
                data-wow-delay="0.6s"
              >
                <div className="icon-bx-wraper bx-style-1 p-a30 m-b15 radius-sm br-col-w1 bg-tpw1">
                  <h5 className="text-white font-weight-300">
                    {t("FooterTitle")}
                  </h5>
                  <p>{t("FooterText")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6 col-sm-6 text-center">
                <span>
                  {t("copyright")}{" "}
                  <a
                    href="https://omarlebda.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("developerName")}
                  </a>{" "}
                  {t("rights")}
                </span>
              </div>
              {/* <div className="col-md-6 col-sm-6 text-right "> 
								<div className="widget-link "> 
									<ul>
										<li><Link to="/help-desk"> Help Desk</Link></li>
										<li><Link to="/privacy-policy">  Refund Policy</Link></li> 
									</ul>
								</div>
							</div> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer3;
