import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'
import { withTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
var bnr3 = require('./../../images/banner/bnr3.jpg');

class About2 extends Component {


    render() {
        const { t } = this.props;
        return (
            <>
                <Header  activePage="about"/>
                
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bnr3 + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">{t('AboutUs')}</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to={"/"}>{t('Home')}</Link></li>
                                        <li>{t('AboutUs')}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                  
                    
                    <div className="content-block">
                    



                        <div className="section-full content-inner bg-white">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 col-md-12 m-b30">
                                        <div className="our-story">
                                            <span>{t('AboutUsTitle')}</span>
                                            <h4 style={{ textAlign: 'justify' }} className="title">{t('AboutUsText.Introduction')}</h4>
                                            <p style={{ textAlign: 'justify' }}>{t('AboutUsText.About')}</p>
                                            <p style={{ textAlign: 'justify' }}>{t('AboutUsText.FinalParagraph')}</p>
                                            {/* <Link to={"/#"} className="site-button">Read More</Link> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 m-b30">
                                    <LazyLoad>
                                        <img src={require("../../images/about/pic6.jpg")} className="radius-sm" alt=""/>
                                    </LazyLoad>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </>
        )
    }
}
export default withTranslation()(About2);