import React, { useState } from "react";
import {Link} from 'react-router-dom'
import LazyLoad from 'react-lazyload';

const images = [
  {
    id: 1,
    name: "agriculture",
    agriculture: true,
    title: "Mining Plant Set Up",
    subTitle: "System",
    imgUrl: require("../../images/portfolio/aroma/096A6094.JPG")
},
{
    id: 3,
    name: "agriculture",
    agriculture: true,
    title: "Mining Plant Set Up",
    subTitle: "System",
    imgUrl: require("../../images/portfolio/aroma/096A6005.JPG")
},
{
    id: 4,
    name: "agriculture",
    agriculture: true,
    title: "Mining Plant Set Up",
    subTitle: "System",
    imgUrl: require("../../images/portfolio/aroma/096A6006.JPG")
},
{
    id: 5,
    name: "agriculture",
    agriculture: true,
    title: "Mining Plant Set Up",
    subTitle: "System",
    imgUrl: require("../../images/portfolio/aroma/096A6007.JPG")
},
{
    id: 6,
    name: "agriculture",
    agriculture: true,
    title: "Mining Plant Set Up",
    subTitle: "System",
    imgUrl: require("../../images/portfolio/aroma/096A6008.JPG")
},
{
    id: 7,
    name: "agriculture",
    agriculture: true,
    title: "Mining Plant Set Up",
    subTitle: "System",
    imgUrl: require("../../images/portfolio/aroma/096A6009.JPG")
},
{
    id: 8,
    name: "agriculture",
    agriculture: true,
    title: "Mining Plant Set Up",
    subTitle: "System",
    imgUrl: require("../../images/portfolio/aroma/096A6010.JPG")
},
{
    id: 9,
    name: "agriculture",
    agriculture: true,
    title: "Mining Plant Set Up",
    subTitle: "System",
    imgUrl: require("../../images/portfolio/aroma/096A6011.JPG")
},
{
    id: 12,
    name: "agriculture",
    agriculture: true,
    title: "Mining Plant Set Up",
    subTitle: "System",
    imgUrl: require("../../images/portfolio/aroma/096A6014.JPG")
},
{
    id: 17,
    name: "agriculture",
    agriculture: true,
    title: "Mining Plant Set Up",
    subTitle: "System",
    imgUrl: require("../../images/portfolio/aroma/096A6019.JPG")
},
{
    id: 18,
    name: "agriculture",
    agriculture: true,
    title: "Mining Plant Set Up",
    subTitle: "System",
    imgUrl: require("../../images/portfolio/aroma/096A6020.JPG")
},
{
    id: 19,
    name: "agriculture",
    agriculture: true,
    title: "Mining Plant Set Up",
    subTitle: "System",
    imgUrl: require("../../images/portfolio/aroma/096A6021.JPG")
},
{
    id: 20,
    name: "agriculture",
    agriculture: true,
    title: "Mining Plant Set Up",
    subTitle: "System",
    imgUrl: require("../../images/portfolio/aroma/096A6022.JPG")
},
{
    id: 22,
    name: "agriculture",
    agriculture: true,
    title: "Mining Plant Set Up",
    subTitle: "System",
    imgUrl: require("../../images/portfolio/aroma/096A6024.JPG")
},
{
    id: 23,
    name: "agriculture",
    agriculture: true,
    title: "Mining Plant Set Up",
    subTitle: "System",
    imgUrl: require("../../images/portfolio/aroma/096A6025.JPG")
},
{
    id: 24,
    name: "agriculture",
    agriculture: true,
    title: "Mining Plant Set Up",
    subTitle: "System",
    imgUrl: require("../../images/portfolio/aroma/096A6026.JPG")
},
{
    id: 25,
    name: "agriculture",
    agriculture: true,
    title: "Mining Plant Set Up",
    subTitle: "System",
    imgUrl: require("../../images/portfolio/aroma/096A6027.JPG")
},
{
    id: 26,
    name: "agriculture",
    agriculture: true,
    title: "Mining Plant Set Up",
    subTitle: "System",
    imgUrl: require("../../images/portfolio/aroma/096A6028.JPG")
},
{
  id: 27,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6029.JPG")
},
{
  id: 28,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6030.JPG")
},
{
  id: 29,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6031.JPG")
},
{
  id: 31,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6033.JPG")
},
{
  id: 32,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6034.JPG")
},
{
  id: 33,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6035.JPG")
},
{
  id: 35,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6037.JPG")
},
{
  id: 40,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6042.JPG")
},
{
  id: 41,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6043.JPG")
},
{
  id: 42,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6044.JPG")
},
{
  id: 43,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6045.JPG")
},
{
  id: 44,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6046.JPG")
},
{
  id: 45,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6047.JPG")
},
{
  id: 46,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6048.JPG")
},
{
  id: 50,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6052.JPG")
},
{
  id: 51,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6053.JPG")
},
{
  id: 52,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6054.JPG")
},
{
  id: 55,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6057.JPG")
},
{
  id: 56,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6058.JPG")
},
{
  id: 57,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6059.JPG")
},
{
  id: 58,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6060.JPG")
},
{
  id: 59,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6061.JPG")
},
{
  id: 60,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6062.JPG")
},
{
  id: 62,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6064.JPG")
},
{
  id: 63,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6065.JPG")
},
{
  id: 64,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6066.JPG")
},
{
  id: 65,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6067.JPG")
},
{
  id: 66,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6068.JPG")
},
{
  id: 67,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6069.JPG")
},
{
  id: 68,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6070.JPG")
},
{
  id: 69,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6071.JPG")
},
{
  id: 70,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6072.JPG")
},
{
  id: 71,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6073.JPG")
},
{
  id: 72,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6074.JPG")
},
{
  id: 73,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6075.JPG")
},
{
  id: 74,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6076.JPG")
},
{
  id: 75,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6077.JPG")
},
{
  id: 76,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6078.JPG")
},
{
  id: 78,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6080.JPG")
},
{
  id: 79,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6081.JPG")
},
{
  id: 80,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6082.JPG")
},
{
  id: 81,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6083.JPG")
},
{
  id: 82,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6084.JPG")
},
{
  id: 83,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6085.JPG")
},
{
  id: 90,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6092.JPG")
},
{
  id: 91,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6093.JPG")
},
{
  id: 92,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6094.JPG")
},
{
  id: 95,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6097.JPG")
},
{
  id: 96,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6097.JPG")
},
{
  id: 97,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6098.JPG")
},
{
  id: 98,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6099.JPG")
},
{
  id: 99,
  name: "agriculture",
  agriculture: true,
  title: "Mining Plant Set Up",
  subTitle: "System",
  imgUrl: require("../../images/portfolio/aroma/096A6003.JPG")
},
// {
//   id: 100,
//   name: "agriculture",
//   agriculture: true,
//   title: "Mining Plant Set Up",
//   subTitle: "System",
//   imgUrl: require("../../images/portfolio/aroma/096A6101.JPG")
// },
// {
//   id: 101,
//   name: "agriculture",
//   agriculture: true,
//   title: "Mining Plant Set Up",
//   subTitle: "System",
//   imgUrl: require("../../images/portfolio/aroma/096A6102.JPG")
// },
// {
//   id: 102,
//   name: "agriculture",
//   agriculture: true,
//   title: "Mining Plant Set Up",
//   subTitle: "System",
//   imgUrl: require("../../images/portfolio/aroma/096A6103.JPG")
// },
// {
//   id: 103,
//   name: "agriculture",
//   agriculture: true,
//   title: "Mining Plant Set Up",
//   subTitle: "System",
//   imgUrl: require("../../images/portfolio/aroma/096A6104.JPG")
// },
// {
//   id: 104,
//   name: "agriculture",
//   agriculture: true,
//   title: "Mining Plant Set Up",
//   subTitle: "System",
//   imgUrl: require("../../images/portfolio/aroma/096A6105.JPG")
// },
// {
//   id: 105,
//   name: "agriculture",
//   agriculture: true,
//   title: "Mining Plant Set Up",
//   subTitle: "System",
//   imgUrl: require("../../images/portfolio/aroma/096A6106.JPG")
// },
// {
//   id: 106,
//   name: "agriculture",
//   agriculture: true,
//   title: "Mining Plant Set Up",
//   subTitle: "System",
//   imgUrl: require("../../images/portfolio/aroma/096A6107.JPG")
// }


];


const getFilterSections = (images)=>{
  const filters = ['all']
  images.map(()=>(image)=>{
    if(filters.indexOf(image.name) === -1){
      filters.push(image.name)
    }
  })
  return filters
}

const filterImages = (filterKey,images)=>{
  const list = images.filter(image =>
    filterKey === 'all' ? image : image.name === filterKey
  );

  return list
}

const Portfolio = props => {
  const [state, setState] = useState({
    list: images,
    filterKey: "all"
  });

  const { list, filterKey } = state;
  
  const filteredList  = filterImages(filterKey,list)
  const filters       = getFilterSections(images)
  const collumnCls = props.col ? props.col :"col-md-3";

  return (
    <div className="content-block">
      <div className="section-full content-inner-2 portfolio text-uppercase bg-gray" id="portfolio">
        <div className="container"> 
          <div className="site-filters clearfix center  m-b40">
            {/* FILTERS */}
            <ul className="filters" data-toggle="buttons">
              {filters.map(filter =>(
                <li className="btn" onClick={() => setState({ ...state, filterKey: filter })}>
                  <div className="site-button-secondry button-sm radius-xl">
                    <span>{filter}</span>
                  </div>
                </li >
              ))}
            </ul>
          </div> 
           
            {/* FILTERED PORTFOLIO LIST */}
            <div className="portfolio_area">
              <div className="row portfolio-grid">
                  {filteredList.map(image => (
                    <div className={collumnCls}>
                      <div className="dlab-box dlab-gallery-box">
                        <div className="dlab-media dlab-img-overlay1 dlab-img-effect">
                            <Link to="/portfolio-details"> <LazyLoad> <img src={image.imgUrl}  alt=""/>  </LazyLoad></Link>
                            {/* <div className="overlay-bx">
                                <div className="overlay-icon"> 
                                    <div className="text-white">
                                        <Link to="/portfolio-details"><i className="fa fa-link icon-bx-xs"></i></Link> 
                                        <span className="check-km" title="Factory Managment">		
                                            <i className="fa fa-picture-o icon-bx-xs"></i> 
                                        </span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        {/* <div className="dez-info p-a30 bg-white">
                            <p className="dez-title m-t0"><Link to="/portfolio-details">{image.title}</Link></p>
                            <p><small>{image.subTitle}</small></p>
                        </div> */}
                      </div>
                    </div>
                  ))}
              </div>
          </div>
        </div>
      </div>
    </div>         


  );
};

export default Portfolio;
