import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import About2 from './pages/about-2';
import Contact2 from './pages/contact-2';
import Faq2 from './pages/faq-2';
import shopProductDetails from './pages/shop-product-details';
import Shop from './pages/shop';
import Index3 from './pages/index-3';
import PortfolioGrid2 from './pages/portfolio-grid-2';

import { Analytics } from "@vercel/analytics/react"

class Router extends Component {
    render() {
        const { isRTL } = this.props;
        return (
            
			//<BrowserRouter>
			<BrowserRouter>
                <div className="page-wraper">
                    <Switch>
                        <Route path='/' exact component={Index3} />
                        <Route path='/about' component={About2} />
                        <Route path='/contact' component={Contact2} />
                        <Route path='/faqs' component={Faq2} />
                        <Route path='/gallery' component={PortfolioGrid2} />
                        <Route path='/products/:id' component={shopProductDetails} />
                        <Route path='/products' component={Shop} />
                    </Switch>
                    <Analytics />
                </div>

            </BrowserRouter>

        )
    }
}

export default Router;